export default {
  config: {},
  userProfile: {},
  error: "",
  spacesList: [],
  orgList: [],
  initiativesList: [],
  initiativeDetail: [],
  featuredResourcesList: [],
  courseOverview: {},
  courseDetail: {},
  courseOverviewStatus: {},
  tags: []
};
