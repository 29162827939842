export default {
  allConfig: state => state.config,
  getUserProfile: state => state.userProfile,
  getErrors: state => state.error,
  getSpacesList: state => state.spacesList,
  getOrgList: state => state.orgList,
  getInitiativesList: state => state.initiativesList,
  getInitiativeDetail: state => state.initiativeDetail,
  getFeaturedResourcesList: state => state.featuredResourcesList,
  getCourseOverviewStatus: state => state.courseOverviewStatus,
  getCourseOverview: state => state.courseOverview,
  getCourseDetail: state => state.courseDetail,
  getNoAuthCourseDetail: state => state.courseDetail,
  getTags: state => state.tags
};
