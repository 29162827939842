import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";

// plugins
import * as Keycloak from "keycloak-js";

import { BootstrapVue } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.config.productionTip = false;

const keyCloakTokenParam = {
  interval: 10000, // in milliseconds - interval to check the validity of token
  nearByTime: 60 // in seconds - It is use to calculate if it will be expire in next 60 seconds
};

fetch("/config/all.json").then(response => {
  response.json().then(config => {
    Vue.prototype.$keycloak = new Keycloak(config.SSO);
    Vue.prototype.$keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false
      })
      .then(authenticated => {
        if (authenticated) {
          const app = new Vue({
            router,
            store,
            render: h => h(App)
          }).$mount("#app");

          app.$store.commit("SET_CONFIG", config);
          setInterval(() => {
            Vue.prototype.$keycloak
              .updateToken(keyCloakTokenParam.nearByTime)
              .then(refreshed => {
                console.info(
                  "Keycloak service",
                  refreshed
                    ? "Token is still valid and not refreshed"
                    : "Token is expired and has been refreshed"
                );
              });
          }, keyCloakTokenParam.interval);
        } else {
          console.info("INIT - KC user not authenticated");
        }
      })
      .catch(err => {
        console.error("ERROR Connecting to Keycloak service", err);
      });
  });
});
