import axios from "axios";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};
export function getURL(baseURL, params = {}) {
  return {
    // GET
    VALIDATE_COURSE_ID: `${baseURL}/credentials/v1/courses/${params.courseId}/validate-course/`,
    GET_AWARD_DETAIL: `${baseURL}/credentials/v1/courses/${params.courseId}/preview/`,
    // POST
    CREATE_AWARD_OF_COMPLETION: `${baseURL}/credentials/v1/courses/${params.courseId}/award-of-completion/`,
    CREATE_COMPLETION_OF_PARTICIPATION: `${baseURL}/credentials/v1/courses/${params.courseId}/confirmation-of-participation/`
  };
}
export default {
  fetchAwards({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_AWARD_DETAIL;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(res => {
        commit("SET_AWARD_DETAIL", res.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  validateCourse({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).VALIDATE_COURSE_ID;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return new Promise((resolve, reject) => {
      axios
        .get(URL, headers)
        .then(res => {
          resolve(res);
          commit("SET_COURSE_DETAIL", res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createAwardOfCompletion({ rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params)
      .CREATE_AWARD_OF_COMPLETION;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    const method = params.type === "create" ? "post" : "patch";
    return new Promise((resolve, reject) => {
      axios[method](URL, params.form, headers)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCompletionOfParticipation({ rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params)
      .CREATE_COMPLETION_OF_PARTICIPATION;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    const method = params.type === "create" ? "post" : "patch";
    return new Promise((resolve, reject) => {
      axios[method](URL, params.form, headers)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
