import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "Home"
    }
  },
  {
    path: "/courses/:id/",
    component: () => import("../views/CourseWrapper.vue"),
    children: [
      {
        path: "overview",
        name: "CourseViewWrapper",
        component: () => import("../components/CourseViewWrapper.vue"),
        meta: {
          title: "Course Overview"
        }
      },
      {
        path: "awards",
        name: "Awards",
        component: () => import("../components/Awards.vue"),
        meta: {
          title: "Awards"
        }
      },
      {
        path: "awards-form/:type",
        name: "AwardForm",
        component: () => import("../components/Awards/AwardForm.vue"),
        alias: "awards-form/:type/edit",
        meta: {
          title: "Awards"
        }
      }
    ]
  },

  {
    path: "/unauthorised-access",
    name: "UnauthorisedAccess",
    component: () => import("../views/Unauthorised.vue"),
    meta: {
      title: "Unauthorised Access"
    }
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/ErrorPage.vue"),
    meta: {
      title: "Error"
    }
  },
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: {
      title: "Page Not Found"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  document.title = "Learning Spaces";
  if (nearestWithTitle) {
    document.title = document.title + " - " + nearestWithTitle.meta.title;
  }

  next();
});
export default router;
