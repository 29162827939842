/* eslint-disable no-unused-vars */
import axios from "axios";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

function getURL(baseURL, params = {}) {
  return {
    // GET
    GET_ALL_LEARNING_SPACES: `${baseURL}/spaces/`,
    GET_SPACE_DETAIL: `${baseURL}/spaces/${params.org}/`,
    GET_COURSE_DETAIL: `${baseURL}/no-auth/courses/${params.course_id}/`,
    LIST_ALL_ORGANISATION: `${baseURL}/spaces/organizations/`,
    LIST_ALL_INITIATIVES: `${baseURL}/spaces/${params.org}/initiatives/`,
    GET_INITIATIVE_DETAIL: `${baseURL}/spaces/${params.org}/initiatives/${params.id}/`,
    GET_FEATURED_COURSES: `${baseURL}/spaces/${params.org}/courses/featured/`,
    GET_COURSE_OVERVIEW: `${baseURL}/courses/overview/${params.course_id}/live/`,
    GET_COURSE_OVERVIEW_STATUS: `${baseURL}/courses/overview/${params.course_id}/status/`,
    GET_TAGS: `${baseURL}/tags/live/?org=${params.org}`,

    //POST
    CREATE_INITIATIVE: `${baseURL}/spaces/${params.org}/initiatives/`,
    UPDATE_FEATURED_COURSES: `${baseURL}/spaces/${params.org}/courses/featured/`,
    CREATE_COURSE_OVERVIEW: `${baseURL}/courses/overview/`,

    //DELETE
    DELETE_INITIATIVE: `${baseURL}/spaces/${params.org}/initiatives/${params.id}/`,

    // PATCH
    UPDATE_COURSE_OVERVIEW: `${baseURL}/courses/overview/${params.course_id}/`,
    PUBLISH_INITIATIVE: `${baseURL}/spaces/${params.org}/initiatives/${params.id}/publish/`,
    UNPUBLISH_INITIATIVE: `${baseURL}/spaces/${params.org}/initiatives/${params.id}/unpublish/`
  };
}

export default {
  // GET
  getSpacesList({ commit, state }) {
    const URL = getURL(state.config.baseURL).GET_ALL_LEARNING_SPACES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_SPACES_LIST", response.data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch spaces list");
      });
  },

  listAllOrganisation({ commit, state }) {
    const URL = getURL(state.config.baseURL).LIST_ALL_ORGANISATION;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_ORG_LIST", response.data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch Organisation list");
      });
  },

  listInitiatives({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).LIST_ALL_INITIATIVES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        let data = response.data;
        data.forEach(item => {
          item.org_short_name = params.org;
        });
        commit("SET_INITIATIVES_LIST", data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch Initiatives list");
      });
  },
  initiativesSummary({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).INITIATIVES_SUMMARY;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        let data = response.data;
        data.forEach(item => {
          item.org_short_name = params.org;
        });
        commit("SET_INITIATIVES_LIST", data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch Initiatives Summary");
      });
  },

  getInitiativeDetail({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_INITIATIVE_DETAIL;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_INITIATIVE_DETAIL", response.data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch Initiative");
      });
  },
  getFeaturedResources({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_FEATURED_COURSES;

    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        let data = response.data.results;

        commit("SET_FEATURED_RESOURCES_LIST", data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch FeaturedResources");
      });
  },
  getCourseDetail({ dispatch, commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_COURSE_DETAIL;

    return axios
      .get(URL)
      .then(response => {
        let data = response.data.data;

        commit("SET_COURSE_DETAIL", data);
        dispatch("getTags", { org: data.org });
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch CourseDetail");
      });
  },
  getNoAuthCourseDetail({ dispatch, commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_COURSE_DETAIL;

    return axios
      .get(URL)
      .then(response => {
        let data = response.data.data;
        commit("SET_COURSE_DETAIL", data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch CourseDetail");
      });
  },
  getCourseOverview({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_COURSE_OVERVIEW;
    return axios
      .get(URL)
      .then(response => {
        let data = response.data;
        commit("SET_COURSE_OVERVIEW", data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch CourseOverview");
      });
  },
  getCourseOverviewStatus({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_COURSE_OVERVIEW_STATUS;

    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        let data = response.data;
        commit("SET_COURSE_OVERVIEW_STATUS", data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch CourseOverview Status");
      });
  },
  getTags({ commit, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_TAGS;

    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        let data = response.data.results;

        commit("SET_TAGS", data);
      })
      .catch(error => {
        commit("SET_ERRORS", "Failed to fetch Tags");
      });
  },

  // POST
  createSpace({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).GET_ALL_LEARNING_SPACES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).then(response => {
      dispatch("getSpacesList");
    });
  },
  createInitiative({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).CREATE_INITIATIVE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).then(response => {
      dispatch("getInitiativesList", { org: params.org });
    });
  },
  createCourseOverview({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).CREATE_COURSE_OVERVIEW;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).then(response => {
      dispatch("getCourseOverview");
    });
  },

  // DELETE
  deleteInitiative({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).DELETE_INITIATIVE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.delete(URL, headers).then(response => {
      dispatch("listInitiatives", { org: params.org });
    });
  },
  // PATCH
  updateCourseOverview({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).UPDATE_FEATURED_COURSES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.patch(URL, params, headers).then(response => {
      dispatch("getCourseOverview", params);
    });
  },
  publishInitiative({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).PUBLISH_INITIATIVE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.patch(URL, {}, headers).then(response => {
      dispatch("getInitiativeDetail", params);
      dispatch("listInitiatives", { org: params.org });
    });
  },
  unpublishInitiative({ dispatch, state }, params) {
    const URL = getURL(state.config.baseURL, params).UNPUBLISH_INITIATIVE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.patch(URL, {}, headers).then(response => {
      dispatch("getInitiativeDetail", params);
      dispatch("listInitiatives", { org: params.org });
    });
  }
};
